var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditTechArea" } },
            [_c("pga-save-button", { on: { click: _vm.CreateTechArea } })],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c("pga-tech-areas-create", {
        model: {
          value: _vm.techAreaCreate.Command,
          callback: function ($$v) {
            _vm.$set(_vm.techAreaCreate, "Command", $$v)
          },
          expression: "techAreaCreate.Command",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }